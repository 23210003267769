import axios from 'axios';
import { parseQueryParams, ROOT_API_URL, ROOT_REPORTING_NODE_API_URL } from 'constant';
import { getFormattedTimeStringForAPI } from 'utils/date_utils';
import { join, set } from 'lodash';
export const CREATE_WORK_ORDER = 'create_work_order';
export const UPDATE_WORK_ORDER = 'update_work_order';
export const FETCH_ALL_WORK_ORDERS = 'fetch_all_work_orders';
export const FETCH_WORK_ORDER_BY_ID = 'fetch_work_order_by_id';
export const FETCH_WORK_ORDER_PART_ENTRY = 'fetch_work_order_part_entrty';
export const FETCH_WORK_ORDER_SUMMARY = 'fetch_work_order_summary';
export const FETCH_WORK_ORDERS = 'fetch_work_orders';
export const DOWNLOAD_WORK_ORDER_REPORT = 'download_work_order_report';
export const DELETE_WORK_ORDER = 'delete_work_order';
export const WORK_ORDER_HISTORY = 'WORK_ORDER_HISTORY';
export const RESOLVE_WORK_ORDER = 'RESOLVE_WORK_ORDER';
export const UPDATE_WORK_ORDER_CUSTOM_FIELDS = 'UPDATE_WORK_ORDER_CUSTOM_FIELDS';
export const FETCH_WORK_ORDER_BREAKDOWN_REPORT = 'FETCH_WORK_ORDER_BREAKDOWN_REPORT';
export function createWorkOrder(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_API_URL}work_orders`, data, config);
    return {
        type: CREATE_WORK_ORDER,
        promise: request,
    };
}
export function updateWorkOrder(accesstoken, id, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.put(`${ROOT_API_URL}work_orders/${id}`, data, config);
    return {
        type: UPDATE_WORK_ORDER,
        promise: request,
    };
}
export function resolveWorkOrderOrLineItems(accesstoken, workOrderId, data) {
    const { resolveWorkOrder, lineItems } = data;
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            resolveWorkOrder,
            lineItems: Array.isArray(lineItems) ? join(lineItems, ',') : lineItems,
        }),
    };
    const request = axios.put(`${ROOT_API_URL}work_orders/resolve/${workOrderId}`, {}, config);
    return {
        type: UPDATE_WORK_ORDER,
        promise: request,
    };
}
export function deleteWorkOrder(accesstoken, id, mapping = null) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    if (mapping) {
        set(config, 'data', mapping);
    }
    const request = axios({
        method: 'DELETE',
        url: `${ROOT_API_URL}work_orders/${id}`,
        ...config,
    });
    return {
        type: DELETE_WORK_ORDER,
        promise: request,
    };
}
export function fetchAllWorkOrder(accesstoken) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_URL}work_orders`, config);
    return {
        type: FETCH_ALL_WORK_ORDERS,
        promise: request,
    };
}
export function fetchWorkOrderById(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_URL}work_orders/${id}`, config);
    return {
        type: FETCH_WORK_ORDER_BY_ID,
        promise: request,
    };
}
export function getWorkOrdePartAndEntry(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_URL}part-entry-store/${id}/parts-last-store`, config);
    return {
        type: FETCH_WORK_ORDER_PART_ENTRY,
        promise: request,
    };
}
export function fetchWorkOrder(accesstoken, params = {}) {
    const { workOrderStatus, workOrderCategory, workOrderPriority, vehicleId, fromDate, toDate, page = 1, size, sort, vehicleDomain, itemId, partId, vendorId, driverId, workOrderDateType, orderNo, } = params;
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            workOrderStatus,
            workOrderCategory,
            workOrderPriority,
            vehicleId,
            fromDate,
            toDate,
            sort: sort !== ',' ? sort : undefined,
            page: page - 1,
            size,
            vehicleDomain,
            itemId,
            partId,
            vendorId,
            driverId,
            workOrderDateType,
            number: orderNo,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}work_orders/listing`, config);
    return {
        type: FETCH_WORK_ORDERS,
        promise: request,
    };
}
export function fetchWorkOrderSummary(accesstoken, params = {}) {
    const { workOrderStatus, workOrderCategory, workOrderPriority, vehicleId, fromDate, toDate, vehicleDomain, itemId, partId, vendorId, driverId, workOrderDateType, } = params;
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            workOrderStatus,
            workOrderCategory,
            workOrderPriority,
            vehicleId,
            fromDate,
            toDate,
            vehicleDomain,
            itemId,
            partId,
            vendorId,
            driverId,
            workOrderDateType,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}work_orders/summary`, config);
    return {
        type: FETCH_WORK_ORDER_SUMMARY,
        promise: request,
    };
}
export function fetchNodeWorkOrderReport(accesstoken, reportFormat, fromDate, toDate, size, workOrderStatus, workOrderCategory, workOrderPriority, vehicleId, partId, timeIn24HrFormatJobAndWorkOrderReport = false) {
    let format = {};
    if (reportFormat === 'DOWNLOAD') {
        format = { responseType: 'arraybuffer' };
    }
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        ...format,
        params: parseQueryParams({
            reportFormat,
            fromDate,
            toDate,
            size,
            workOrderStatus,
            workOrderCategory,
            workOrderPriority,
            vehicleId,
            partId,
            timeIn24HrFormatJobAndWorkOrderReport,
        }),
    };
    const request = axios.get(`${ROOT_REPORTING_NODE_API_URL}reports/getWorkOrdersReport`, config);
    return {
        type: DOWNLOAD_WORK_ORDER_REPORT,
        promise: request,
    };
}
export function fetchWorkOrderBreakdownReport(params) {
    const config = {
        responseType: 'blob',
    };
    const request = axios.post(`${ROOT_REPORTING_NODE_API_URL}reports/triggerWorkOrderBreakdownReport`, params, config);
    return {
        type: FETCH_WORK_ORDER_BREAKDOWN_REPORT,
        promise: request,
    };
}
export function fetchWorkOrderHistory(accesstoken, params = {}) {
    const { fromDate, toDate, partId } = params;
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            partId,
            fromDate: getFormattedTimeStringForAPI(fromDate),
            toDate: getFormattedTimeStringForAPI(toDate),
        }),
    };
    const request = axios.get(`${ROOT_API_URL}work_orders/audit-history`, config);
    return {
        type: WORK_ORDER_HISTORY,
        promise: request,
    };
}
export function updateWorkOrderCustomField(accesstoken, customFields) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({}),
    };
    const request = axios.patch(`${ROOT_API_URL}work_orders/custom-fields`, customFields, config);
    return {
        type: UPDATE_WORK_ORDER_CUSTOM_FIELDS,
        promise: request,
    };
}
